

.ServicosCategorias ion-list {
    margin-right: .5em;
    margin-left: .5em;
}

.ServicosCategorias ion-list ion-list-header {
    font-weight: bold;
    font-size: .9em;
    min-height: 1.7em;
    margin-top: 20px;
}

.ServicosCategorias ion-list .contentList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: medium;
}

.ServicosCategorias ion-list .contentList h1 {
    margin: 0;
    font-size: .8em;
}

.ServicosCategorias ion-list .contentList h2 {
    margin: 0;
    font-size: 1em;
}

ion-footer ion-toolbar.ServicosCategorias {
    padding-bottom: 0;
    --border-width: 0px;

}