#map {
  height: 100%;
  width: 100vw;
}


.my-custom-class .modal-wrapper ion-button {
  margin-left: 1em;
  margin-right: 1em;
  min-height: 35px;
}

.my-custom-class .modal-wrapper {
  max-height: 100%;
  /*box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.438);
  background: var(--ion-color-branco);*/
}

.my-custom-class .modal-wrapper .ion-page {
  justify-content: space-around;
  max-height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}



.my-custom-class.modal .modal-wrapper .ion-page video {
  position: absolute;
  width: 100%;
  height: 100%;
}

.my-custom-class.modal .modal-wrapper {
  /*background-color: black;*/
  background-color: white;
  width: 100%;

}


.my-custom-class.modal ion-label.helper {
  font-size: larger;
  position: absolute;
  top: 0em;
  padding: 1em;
  padding-bottom: .5em;
  width: 100%;
  /*color: white;*/
  color: black;
  text-align: center;
  /*background-color: rgba(0, 0, 0, 0.438);*/
}

.my-custom-class.modal ion-label.copiar {
  font-size: small;
}

.my-custom-class.modal .actions {
  position: absolute;
  bottom: 0em;
  padding-bottom: 1em;
  padding-top: .5em;
  /*background-color: rgba(0, 0, 0, 0.438);*/
}

.my-custom-class.modal .actions ion-label {
  color: white
}


.table-status {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}

.status-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-all {
  background-color: rgb(0, 0, 0);
  /* box-shadow: 0px 0px 10px rgb(0, 0, 0); */
}

.status-available {
  background-color: rgb(18, 221, 0);
  /* box-shadow: 0px 0px 10px rgb(21, 255, 0); */
}

.status-occupied {
  background-color: rgb(255, 0, 0);
  /* box-shadow: 0px 0px 10px rgb(255, 0, 0); */
}

.table-status ion-label {
  color: black;
  font-weight: bold;
}

.clickable {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}


.comanda-flat-button-size ion-label {
  font-size: 0.8rem;
  /* Ajuste o tamanho da fonte, se necessário */
}

.comanda-flat-button-size {
  width: 100%;
  height: 80px;
}