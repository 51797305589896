
.text-content p {
    margin-top: 16px;
    margin-bottom: 16px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 36px;
}

.button-container ion-button {
    width: calc(100% - 2.7em);
    height: 2.75em;
}

a {
    color: var(--ion-color-primary);
}