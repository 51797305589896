.ProdutosCatalogo ion-list {
    margin-right: .5em;
    margin-left: .5em;
}

.ProdutosCatalogo ion-list ion-list-header {
    font-weight: bold;
    font-size: .9em;
    min-height: 1.7em;
    margin-top: 20px;
}

.ProdutosCatalogo ion-list .contentList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: medium;
}

.ProdutosCatalogo ion-list .contentList h1 {
    margin: 0;
    font-size: .8em;
}

.ProdutosCatalogo ion-list .contentList h2 {
    margin: 0;
    font-size: 1em;
}

ion-footer ion-toolbar.ProdutosCatalogo {
    padding-bottom: 0;
    --border-width: 0px;

}

div.ProdutosCatalogoSlides {
    padding-top: 0px;
    height: calc(100% - 3em);
    background: #F2F2F2;
}

div.ProdutosCatalogoSlides .swiper-pagination-bullet {
    background: #333;
    opacity: 0.3;
}

div.ProdutosCatalogoSlides .swiper-pagination-bullet-active {
    background: #3880ff;
    opacity: 1;
}

div.ProdutosCatalogoSlides {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
}

.div-qrcode {
    text-align: center;
}

.header-qrcode h1 {
    color: #000;
    font-size: 24px;
    margin: 0;
}

.qr-code {
    margin: 20px 0;
}

.qr-code img {
    width: 250px;
    height: 250px;
}

.copy-code-catalogo-prod {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    word-wrap: break-word;
    word-break: break-all;
    /* Adiciona quebra de palavra no código */
}