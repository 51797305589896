.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

/* .alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
} */

.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
/* .alert-checkbox-group::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
} */

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


ion-content.equipecontent {
    --background: #F2F2F2;
}

.Equipe {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Equipe ion-label.invisivel {
    color: transparent;
    display: none;
}

.Equipe a {
    text-decoration: none;
}

ion-slides.EquipeSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.EquipeSlides {
    position: relative;
    height: auto;
    min-height: 100% !important;
}

ion-slides.EquipeSlides ion-slide ion-card {
    width: calc(100% - 2em);
    margin: 1em;
}

ion-slides.EquipeSlides ion-slide ion-card ion-card-header {
    position: relative;
    padding: 0px;
    margin: 17px;
}

ion-slides.EquipeSlides ion-slide ion-card-title {
    font-weight: bold;
    font-size: larger;
    text-align: left;
}



ion-card.card {
    /*background-color: coral;*/
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho {
    text-align: left;
    font-size: large;
}


ion-slides.EquipeSlides ion-slide .status {
    position: absolute;
    /*position: absolute;*/
    bottom: 10px;

    right: 10px;
    display: flex;
    font-size: x-small;
}

ion-card.equipeCard {
    margin-top: 30px;
}

ion-card-content div.equipe {
    display: flex;
    margin-top: 5px;
}

ion-card-content div.equipe ion-label {
    padding-right: 10px;
    min-width: 100px;
    overflow: hidden;
    text-align: left;
}

ion-card-content div.equipe ion-label.equipeTexto {
    font-weight: bold;
    overflow: hidden;
    color: black;
    width: auto;
    overflow: hidden;

}


ion-slides.EquipeSlides ion-slide .pisca {
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

ion-slides.EquipeSlides ion-slide .piscaverde {
    animation-name: piscarverde;
}

ion-slides.EquipeSlides ion-slide .piscavermelho {
    animation-name: piscarvermelho;
}

ion-slides.EquipeSlides ion-slide .piscaamarelo {
    animation-name: piscaramarelo;
}