.background-intro {
    width: 100%;
    height: 100%;
    background: var(--ion-color-amarelo) !important;
}

.Intro ion-icon {
    position: absolute;
    bottom: 0;
    width: 8em;
    height: 8em;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.Intro ion-img {
    position: absolute;
    bottom: 0;
    width: 8em;
    height: 8em;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}