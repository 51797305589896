table.relProjetos,
th.relProjetos,
td.relProjetos {
	border: 1px solid;
	border-color: #333;
	padding: 5px;
	font-size: 14px;
}

tr.relProjetos:nth-child(even) {
	background-color: #f2f2f2
}

th.relProjetos {
	background-color: #ff7000;
	color: black;
}

/*tr:hover {background-color: rgb(189, 189, 189);}*/

.center {
	margin: auto;
	width: 90%;
	padding: 0px;
}

@media only screen and (max-width: 950px),
(min-device-width: 950px) and (max-device-width: 1024px) {

	/* Force table to not be like tables anymore */
	table.relProjetos,
	thead.relProjetos,
	tbody.relProjetos,
	th.relProjetos,
	td.relProjetos,
	tr.relProjetos {
		display: block;
		border: 0px solid;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	th.relProjetos {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr.relProjetos {
		margin: 0 0 1rem 0;
	}

	tr.relProjetos:nth-child(odd) {
		background: #ccc;
	}

	td.relProjetos {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
	}

	td.relProjetos:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 0;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}

	/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/

	td.relProjetos:nth-of-type(1):before {
		content: "#";
	}

	td.relProjetos:nth-of-type(2):before {
		content: "Nome";
	}

	td.relProjetos:nth-of-type(3):before {
		content: "Status";
	}

	td.relProjetos:nth-of-type(4):before {
		content: "Data";
	}

	td.relProjetos:nth-of-type(5):before {
		content: "Cliente";
	}

	td.relProjetos:nth-of-type(6):before {
		content: "Telefone";
	}

	td.relProjetos:nth-of-type(7):before {
		content: "Valor";
	}

	td.relProjetos:nth-of-type(8):before {
		content: "Realizado";
	}

	td.relProjetos:nth-of-type(9):before {
		content: "Avaliação";
	}

	td.relProjetos:nth-of-type(10):before {
		content: "Comentário";
	}


	/*
    Utilizar caso queira ocultar algum TD específico
    tr td:nth-child(1) { display:none; visibility:hidden; };
    */
}