ion-tab-bar {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

}

.add-btn {
  ion-icon {
    color: #fcad07;
    padding: 0px;
    margin: 0px;
    font-size: 55px !important;
    position: absolute;
    margin: auto;
  }
}

.disable-action-sheet-btns {
  cursor: not-allowed;
  color: rgb(229, 229, 229) !important;
}

.disable-action-sheet-btns>* {
  pointer-events: none;
}


ion-fab-button[data-desc] {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 28px;
  /* Ajuste a distância do botão a partir da parte inferior da página */
  z-index: 999;
  width: 70px; /* Ajuste a largura do botão conforme necessário */
  height: 70px; /* Ajuste a altura do botão conforme necessário */
  /* Ajuste conforme necessário */
}

ion-fab-button[data-desc]::after {
  content: attr(data-desc);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  /* Ajuste a distância do texto a partir da parte inferior da página */

  padding: 9px;
  border-radius: 15px;
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 12px;

  z-index: 998;
  /* Ajuste para ficar atrás do botão */
}

.custom-fab-button {
  z-index: 999;
  margin-left: -7px;
  width: 70px;
  /* Ajuste a largura do botão conforme necessário */
  height: 70px;
}