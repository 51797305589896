.ProjetosLiberar ion-list {
    margin-right: .5em;
    margin-left: .5em;
}

.ProjetosLiberar ion-list ion-list-header {
    font-weight: bold;
    font-size: .9em;
    min-height: 1.7em;
    margin-top: 20px;
}

.ProjetosLiberar ion-list .contentList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: medium;
}

.ProjetosLiberar ion-list .contentList h1 {
    margin: 0;
    font-size: .8em;
}

.ProjetosLiberar ion-list .contentList h2 {
    margin: 0;
    font-size: 1em;
}

ion-footer ion-toolbar.ProjetosLiberar {
    padding-bottom: 0;
    --border-width: 0px;

}

div.ProjetosCupomSlides {
    padding-top: 0px;
    height: calc(100% - 3em);
    background: #F2F2F2;
}

div.ProjetosCupomSlides .swiper-pagination-bullet {
    background: #333;
    opacity: 0.3;
}

div.ProjetosCupomSlides .swiper-pagination-bullet-active {
    background: #3880ff;
    opacity: 1;
}

div.ProjetosCupomSlides {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
}

/* .autocomplete-list {
    position: absolute;
    top: 100px;    
    left: 16px;    
    right: 16px;    
    z-index: 1000;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
} */