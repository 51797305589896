.container-banner {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px;
}

.image-banner {
  padding-top: 30px;
  width: 100%;
  border-radius: 8px;
}

.textContainer-banner {
  padding: 16px;
  text-align: center;
}

.title-banner {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 8px;
}

.subTitle-banner {
  font-size: 100px;
  font-weight: bold;
}

.subTitle2-banner {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.description-banner {
  font-size: 16px;
  margin-bottom: 16px;
  color: #666;
}
