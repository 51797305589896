#containerInicioCategorias ion-card {
    width: 100%;
    margin: 0 0 10px 0;
    break-inside: avoid;
}

#containerInicioCategorias ion-grid {
    padding-left: 14px;
    padding-right: 14px;
}

#containerInicioCategorias ion-grid ion-row ion-col {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.qr-code-pagamento {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-code-pagamento-margin {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
}

.qr-code-pagamento img {
    width: 250px;
    height: 250px;
}

.copy-code-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    word-wrap: break-word;
    word-break: break-all; /* Adiciona quebra de palavra no código */
}

.payment-code {
    max-width: 80%; /* Limita a largura para melhorar a legibilidade */
    white-space: pre-wrap; /* Permite que o texto quebre em várias linhas */
    word-wrap: break-word; /* Quebra a palavra se necessário */
    word-break: break-all;
}

.payment-info {
    font-size: 16px;
    margin-top: 15px;
}

.payment-title {
    font-size: 24px;
    font-weight: bold;
}

.payment-section {
    margin-bottom: 10px;
}
