.TipoConta {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1em;
    text-align: center;
}

.TipoConta ion-button{
    width: calc(100% - 4em);
    height: 2.75em;
    margin: .5em 2em;
}

.content-wrapper {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.titulo {
    margin-bottom: 1.5em;
    font-size: 1.2em;
}

.radio-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;
}

.center-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}
