.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

/* .alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
} */

.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
/* .alert-checkbox-group::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
} */

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


div.ProjetosSlides {
    padding-top: 0px;
    height: calc(100% - 3em);
    background: #F2F2F2;
}

div.ProjetosSlides .swiper-pagination-bullet {
    background: #333;
    opacity: 0.3;
}

div.ProjetosSlides .swiper-pagination-bullet-active {
    background: #3880ff;
    opacity: 1;
}

div.ProjetosSlides {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 50px;
}


ion-segment ion-label {
    color: #000;
    font-size: 17px;
    font-weight: bold;
}

.Projetos {
    display: flex;
    flex-direction: column;
    overflow: hidden;

}

.Projetos ion-label.invisivel {
    color: transparent;
    display: none;
}

.Projetos a {
    text-decoration: none;
}

div.ProjetosSlides {

    position: relative;
    height: auto;
    min-height: 100% !important;

}

div.ProjetosSlides ion-card {

    width: calc(100% - 2em);
    margin: 1em;
    margin-top: 2em;
    overflow: visible;
}


div.ProjetosSlides ion-card-title {
    /*font-weight: bold;
    font-size: medium;*/
    text-align: left;
}

ion-card {
    border-radius: 10px;
}

ion-card.card {
    background-color: #F2F2F2;
    border: 0px;
    left: 0px;
    box-shadow: none !important;
}

.cabecalho {
    text-align: left;
    font-size: large;
}

div.ProjetosSlides ion-card-content {
    /*display: flex;*/
    text-align: left;
    /*margin: 0px;*/
}



ion-card-content div.projeto {
    display: flex;
    margin-top: 0px;

}

ion-card-content div.projeto ion-label {
    padding-right: 10px;
    width: 100px;
    overflow: hidden;
}

ion-card-content div.projeto ion-label.projetoTexto {
    font-weight: bold;
    color: black;
    width: auto;
}

ion-card-content div.projeto ion-icon {
    margin-left: auto;
    width: 16px;
    height: 16px;
}

#icone-chatbox {
    margin-left: auto;
    width: 18px;
    height: 18px;
}


ion-card-content div.cardTail {
    margin-top: 15px;
    /*display: inline-block;*/
    display: flex;
}

div.ProjetosSlides ion-card-content span {
    display: block;
    margin: auto;
    text-align: center;
    font-weight: bold;
    color: var(--ion-color-primary);

}

div.ProjetosSlides .status {
    position: absolute;
    bottom: 15px;
    right: 10px;
    display: flex;
    font-size: x-small;
}

div.ProjetosSlides ion-card-subtitle {
    /*display: flex;*/
    text-align: center;
    /*margin: 0px;*/
}

div.ProjetosSlides ion-card-subtitle>* {
    align-self: center;
    margin-right: .3em;
}

div.ProjetosSlides ion-card ion-card-header {
    position: relative;
    padding: 0px;
    margin: 0px 0px 0px 10px;
}

div.tempo div.duracao {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: -10px;

}

div.tempo div.duracaoTexto {
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin: 0;
    padding-top: -10px;
    text-align: center;
}

div.tempo {
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -30px;
    margin: auto;
    right: 10px;
    left: 10px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: var(--ion-color-tertiary);
    /*#7fee1d;*/
    z-index: 999;
}

div.loja {
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -25px;
    margin: 0;
    padding: 4px;
    left: 0px;
    color: black;
    /*left: 0px;    */
    width: auto;
    height: 25px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    background: var(--ion-color-primary);
    /*#7fee1d;*/
    z-index: 999;
}

div.tempo2 {
    all: revert;
    position: absolute;
    overflow: hidden;
    top: -25px;
    margin: auto;
    right: 10px;
    /*left: 0px;    */
    width: 200px;
    height: 25px;
    border-radius: 10px 10px 0 0;
    background: #ebebeb;
    /* background: var(--ion-color-tertiary);  */
    /*#7fee1d;*/
    z-index: 999;
}

div.tempo2 div.duracao {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* color: white; */
    color: black;
    font-size: 11px;
    font-weight: bolt;
    padding-bottom: -10px;

}

div.tempo2 div.duracaoTexto {
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin: 0;
    padding-top: -10px;
    text-align: center;
}

@keyframes piscarverde {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }

    50% {
        box-shadow: 0px 0px 10px rgb(21, 255, 0);
        ;
        background: rgb(18, 221, 0);
    }

    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscarvermelho {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }

    50% {
        box-shadow: 0px 0px 10px rgb(255, 30, 0);
        ;
        background: rgb(221, 0, 0);
    }

    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

@keyframes piscaramarelo {
    0% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }

    50% {
        box-shadow: 0px 0px 10px rgb(255, 217, 0);
        ;
        background: rgb(255, 196, 0);
    }

    100% {
        box-shadow: 0px 0px 0px transparent;
        background: gray;
    }
}

div.ProjetosSlides {
    background: #F2F2F2;
}

div.ProjetosSlides .pisca {
    width: 10px;
    height: 10px;
    background: gray;
    align-self: center;
    margin-left: 5px;
    border-radius: 10px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

div.ProjetosSlides .piscaverde {
    animation-name: piscarverde;
}

div.ProjetosSlides .piscavermelho {
    animation-name: piscarvermelho;
}

div.ProjetosSlides .piscaamarelo {
    animation-name: piscaramarelo;
}

ion-modal.data {
    --width: 290px;
    --height: 330px;
    --border-radius: 8px;
}

ion-modal.data ion-datetime {
    height: 330px;
}


#cart-badge {
    position: absolute;
    top: -6px;
    right: -6px;
    border-radius: 10px;
    font-size: 7pt;
}

#cart-badge-filtro {
    z-index: 9999;
    position: absolute;
    top: 0px;
    right: -14px;
    font-size: 10pt;
}