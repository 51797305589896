.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

/* .alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
} */

.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
/* .alert-checkbox-group::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
} */

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


.divPainel {
    background: #F2F2F2;
    min-height: 100% !important;
    text-align: center;
    align-items: center;
}

.divPainel ion-card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 20px;
    margin-top: 10px;
}

.divPainel .chartPrincipal {
    font-weight: bold;
    font-size: 70px;
}

.divPainel .textovertical {
    width: 1px;
    word-wrap: break-word;
    /* font-family: monospace;  */
    white-space: pre-wrap;
    text-align: center;
    align-items: center;
    /* font-weight: bold; */
    font-size: xx-small;
}



.divPainel ion-card-content ion-card {
    width: calc(100% - 2em);
    margin: 1em;
}

.divPainel ion-card-content ion-card-title {
    font-weight: bold;
    font-size: medium;
    text-align: center;
}

.container .box {
    display: table;
    background: #eeeeee;
    width: 100%;
}

.container .box .box-row {
    display: table-row;
}

.container .box .box-cell {
    display: table-cell;
    width: 20%;
    padding: 10px;
}

.container .box .box-cell-projetos {
    display: table-cell;
    width: 40px;
    padding-left: 10px;
}

.container .box .box-cell-vendas {
    display: table-cell;
    width: 40px;
    padding-left: 10px;
}